<script setup>
import { computed, useSlots } from "vue";
import Pending from "../PendingIcon";

const slots = useSlots();
const emit = defineEmits(["click"]);

defineProps({
  type: {
    type: String,
    default: "button",
    validator: (value) => ["button", "submit"].includes(value),
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  pendingLabel: {
    type: String,
    default: "",
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  isTertiary: {
    type: Boolean,
    default: false,
  },
});

const onClick = (event) => {
  emit("click", event);
};
const hasIconSuffix = computed(() => !!slots["icon-suffix"]);
const hasIconPrefix = computed(() => !!slots["icon-prefix"]);
</script>

<template>
  <button
    class="button"
    :class="{
      'button--pending': isPending,
      'button--secondary': isSecondary,
      'button--tertiary': isTertiary,
    }"
    :type="type"
    @click="onClick"
  >
    <span class="button__main">
      <span class="button__pending" v-if="isPending">
        <span
          class="button__pending__content"
          v-if="pendingLabel"
          v-html="pendingLabel" />
        <pending
          class="button__pending__icon"
          is-weakest
          aria-busy="true"
          aria-live="polite"
          aria-label="Pending"
      /></span>
      <span class="button__content"
        ><span class="button__icon-prefix" v-if="hasIconPrefix"
          ><slot name="icon-prefix" /></span
        ><span class="button__label"><slot>Submit</slot></span
        ><span class="button__icon-suffix" v-if="hasIconSuffix"
          ><slot name="icon-suffix" /></span
      ></span>
    </span>
  </button>
</template>

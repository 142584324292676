/**
 * TODO Move transaction API calls and other methods here,
 * leaving the PDTF composable to just manipulate state and PDTF
 * validation etc.
 */
import axios from "axios";
import productType from "@/enums/product-type";
import useAuth from "@/composables/auth";
import {
  MOVEREADY_PDTF_API_URL,
  MOVEREADY_ORGANISATION_API_URL,
} from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import { usePDTF } from "@/composables/pdtf";
import Honeybadger from "@honeybadger-io/js";

const { getAccessToken } = useAuth();
const { pdtfState, attachments, transactionId } = usePDTF();

const transaction = ref(null);
const purchasedProducts = ref([]);
const requiresPurchase = ref([]);
const isFetchingTransaction = ref(false);

const fetchPurchasedProducts = async (transactionId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/products`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    purchasedProducts.value = response.data.purchasedProducts;
    requiresPurchase.value = response.data.requiresPurchase;
  } catch (ex) {
    console.error(ex);
    purchasedProducts.value = [];
    throw ex;
  }
};

const isUpdatingTransactionProducts = ref(false);

const updateTransactionProductRequirement = async (
  transactionId,
  productPurchaseRequirement
) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isUpdatingTransactionProducts.value = true;

  try {
    await axios.patch(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/requires-purchase`,
      {
        requiresPurchase: productPurchaseRequirement,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Perhaps have this returned by the endpoint?
    requiresPurchase.value = [
      ...requiresPurchase.value,
      productPurchaseRequirement,
    ];

    return purchasedProducts.value;
  } catch (ex) {
    // A conflicting state, already patched.
    if (ex.response.status === 409) return;
    transaction.value = null;
    throw ex;
  } finally {
    isUpdatingTransactionProducts.value = false;
  }
};

const updateTransactionProducts = async (transactionId, productToPurchase) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isUpdatingTransactionProducts.value = true;

  try {
    await axios.patch(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/products`,
      {
        purchasedProduct: productToPurchase,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Perhaps have this returned by the endpoint?
    purchasedProducts.value = [...purchasedProducts.value, productToPurchase];
    return purchasedProducts.value;
  } catch (ex) {
    // A conflicting state - the Property Pack has been purchased.
    if (ex.response.status === 409) return;
    transaction.value = null;
    throw ex;
  } finally {
    isUpdatingTransactionProducts.value = false;
  }
};

const orderLandlordIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.LANDLORD_IDENTITY_CHECK
  );
};

const orderTenantIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.TENANT_IDENTITY_CHECK
  );
};

const orderBuyerIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.BUYER_IDENTITY_CHECK
  );
};

const orderSellerIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.SELLER_IDENTITY_CHECK
  );
};

const confirmMaterialInformationEstateAgentPurchase = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.MATERIAL_INFORMATION_PACK
  );
};

const createContractReadyPurchaseOrderForVendor = async (transactionId) => {
  await updateTransactionProductRequirement(
    transactionId,
    productType.CONTRACT_READY_PACK_SELLER
  );
};

const confirmMaterialInformationSellerPurchase = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.CONTRACT_READY_PACK_SELLER
  );
};

const hasPurchasedLandlordIdentityCheck = computed(() => {
  return purchasedProducts.value.some((product) =>
    [productType.LANDLORD_IDENTITY_CHECK].includes(product)
  );
});

const hasPurchasedTenantIdentityCheck = computed(() => {
  return purchasedProducts.value.some((product) =>
    [productType.TENANT_IDENTITY_CHECK].includes(product)
  );
});

const hasPurchasedSellerIdentityCheck = computed(() => {
  return purchasedProducts.value.some((product) =>
    [productType.SELLER_IDENTITY_CHECK].includes(product)
  );
});
const hasPurchasedBuyerIdentityCheck = computed(() => {
  return purchasedProducts.value.some((product) =>
    [productType.BUYER_IDENTITY_CHECK].includes(product)
  );
});

const hasPurchasedMaterialInformationPack = computed(() => {
  return purchasedProducts.value.some((product) =>
    [
      productType.MATERIAL_INFORMATION_PACK,
      productType.MATERIAL_INFORMATION_PACK_SELLER,
    ].includes(product)
  );
});

const hasPurchasedContractReadyPack = computed(() => {
  return purchasedProducts.value.some((product) =>
    [
      productType.CONTRACT_READY_PACK,
      productType.CONTRACT_READY_PACK_SELLER,
    ].includes(product)
  );
});

/**
 * Only the seller MI product is required to be purchased.
 * But for completeness, we'll check for both.
 */
const isSellerMaterialInformationPurchaseRequested = computed(() => {
  return requiresPurchase.value.some((product) =>
    [
      productType.MATERIAL_INFORMATION_PACK,
      productType.MATERIAL_INFORMATION_PACK_SELLER,
    ].includes(product)
  );
});

const isSellerContractReadyPurchaseRequested = computed(() => {
  return requiresPurchase.value.some((product) =>
    [
      productType.CONTRACT_READY_PACK,
      productType.CONTRACT_READY_PACK_SELLER,
    ].includes(product)
  );
});

const fetchTransaction = async (transactionId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFetchingTransaction.value = true;

  try {
    const fetchTransactionResponse = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    transaction.value = fetchTransactionResponse.data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  } finally {
    isFetchingTransaction.value = false;
  }
};

const fetchPublicTransaction = async (newTransactionId) => {
  const appCheckToken = await getAppCheckToken();
  transactionId.value = newTransactionId;
  pdtfState.value = { transactionId: newTransactionId };
  attachments.value = {};
  try {
    const publicStateResponse = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/public/transactions/${newTransactionId}/state`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    pdtfState.value = publicStateResponse.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch public state.",
      name: "transaction.js",
      params: {
        transactionId,
      },
    });
  }
};

const hasPurchasedIdentityChecks = computed(() => {
  return (
    hasPurchasedBuyerIdentityCheck.value ||
    hasPurchasedLandlordIdentityCheck.value ||
    hasPurchasedSellerIdentityCheck.value ||
    hasPurchasedTenantIdentityCheck.value
  );
});

const purchaseProducts = async (transactionId, serviceOptions = []) => {
  const productsToPurchase = [];

  if (
    !hasPurchasedMaterialInformationPack.value &&
    serviceOptions.includes(productType.MATERIAL_INFORMATION_PACK)
  ) {
    productsToPurchase.push(
      confirmMaterialInformationEstateAgentPurchase(transactionId)
    );
  }

  if (
    !hasPurchasedSellerIdentityCheck.value &&
    serviceOptions.includes(productType.SELLER_IDENTITY_CHECK)
  ) {
    productsToPurchase.push(orderSellerIdentityChecks(transactionId));
  }

  if (
    !hasPurchasedLandlordIdentityCheck.value &&
    serviceOptions.includes(productType.LANDLORD_IDENTITY_CHECK)
  ) {
    productsToPurchase.push(orderLandlordIdentityChecks(transactionId));
  }

  if (
    !hasPurchasedTenantIdentityCheck.value &&
    serviceOptions.includes(productType.TENANT_IDENTITY_CHECK)
  ) {
    productsToPurchase.push(orderTenantIdentityChecks(transactionId));
  }

  if (
    !hasPurchasedBuyerIdentityCheck.value &&
    serviceOptions.includes(productType.BUYER_IDENTITY_CHECK)
  ) {
    productsToPurchase.push(orderBuyerIdentityChecks(transactionId));
  }

  if (
    !hasPurchasedContractReadyPack.value &&
    serviceOptions.includes(productType.CONTRACT_READY_PACK_SELLER)
  ) {
    productsToPurchase.push(
      createContractReadyPurchaseOrderForVendor(transactionId)
    );
  }

  return Promise.all(productsToPurchase);
};

const updateTransactionStatus = async (transactionId, status) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.patch(
      `${MOVEREADY_ORGANISATION_API_URL}/transactions/${transactionId}/status`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    transaction.value.status = status;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Unable to update transaction status.",
      name: "transaction.js",
      params: {
        transactionId,
        status,
      },
    });
  }
};

export default function useTransaction() {
  return {
    updateTransactionStatus,
    purchaseProducts,
    confirmMaterialInformationEstateAgentPurchase,
    confirmMaterialInformationSellerPurchase,
    createContractReadyPurchaseOrderForVendor,
    fetchPublicTransaction,
    fetchPurchasedProducts,
    hasPurchasedBuyerIdentityCheck,
    hasPurchasedContractReadyPack,
    hasPurchasedIdentityChecks,
    hasPurchasedLandlordIdentityCheck,
    hasPurchasedMaterialInformationPack,
    hasPurchasedSellerIdentityCheck,
    hasPurchasedTenantIdentityCheck,
    isSellerContractReadyPurchaseRequested,
    isSellerMaterialInformationPurchaseRequested,
    orderLandlordIdentityChecks,
    orderSellerIdentityChecks,
    orderTenantIdentityChecks,
    orderBuyerIdentityChecks,
    purchasedProducts,
    requiresPurchase,
    fetchTransaction,
    transaction,
    isFetchingTransaction,
    updateTransactionProductRequirement,
  };
}

import { ref } from "vue";

const DEFAULT_LAYOUT = "DefaultLayout";
const DEFAULT_IS_ACCOUNT_PAGE = false;
const currentLayoutName = ref(DEFAULT_LAYOUT);
const isAccountPage = ref(false);

const setLayout = (newLayoutName) => {
  currentLayoutName.value = newLayoutName;
};

const setDefaultLayout = () => {
  currentLayoutName.value = DEFAULT_LAYOUT;
};

const setDefaultIsAccountPage = () => {
  isAccountPage.value = DEFAULT_IS_ACCOUNT_PAGE;
};

const setIsAccountPage = (newIsAccountPage) => {
  isAccountPage.value = newIsAccountPage;
};

export default function useSiteLayout() {
  return {
    currentLayoutName,
    isAccountPage,
    setDefaultIsAccountPage,
    setDefaultLayout,
    setIsAccountPage,
    setLayout,
  };
}

import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { functions } from "@/firebase";
import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

const downloadFile = (url, filename) => {
  fetch(url)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = filename;
      a.click();
    });
};

const generateAndDownloadPdf = async (
  path,
  filename = "Moverly.pdf",
  waitForSelector = null
) => {
  const runGeneratePdfFromWeb = httpsCallable(
    functions,
    "generatePdfFromWebPage",
    {
      limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
    }
  );
  const userUid = getAuth().currentUser.uid;

  await runGeneratePdfFromWeb({
    path,
    userUid: userUid,
    options: {
      format: "A4",
      printBackground: true,
      displayHeaderFooter: false,
      margin: {
        top: "38px",
        right: "38px",
        bottom: "38px",
        left: "38px",
      },
    },
    selector: waitForSelector,
  }).then((response) => {
    createDownloadableFile({ buffer: response.data, filename });
  });
};

const formatFileNameForDownload = (dirtyFileName) => {
  const cleanFileName = dirtyFileName.replace(/\s+/gi, "_");

  return cleanFileName;
};

const generateAndDownloadTaFormPdf = async ({
  transactionId,
  formName,
  filename,
}) => {
  const populatePif = httpsCallable(functions, "populatePIFForm", {
    limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
  });
  await populatePif({
    formName,
    transactionId,
  }).then((response) => {
    createDownloadableFile({ buffer: response.data, filename });
  });
};

const createDownloadableFile = ({ buffer, filename }) => {
  const blob = new Blob([new Uint8Array(Object.values(buffer))], {
    type: "pdf",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

const filenameFromHeaders = (headers) => {
  const filename = headers["content-disposition"].split("filename=")[1];

  return filename;
};

const createDownloadableFromBlob = ({ data, filename }) => {
  const [, extension] = filename.split(".");

  const blob = new Blob([data], {
    type: contentTypeByExtension(extension),
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

const contentTypeByExtension = (extension) => {
  const contentType = {
    pdf: "application/pdf",
  };
  const defaultContentType = "application/octet-stream";

  return contentType[extension] || defaultContentType;
};

export default function useDownload() {
  return {
    contentTypeByExtension,
    filenameFromHeaders,
    createDownloadableFromBlob,
    downloadFile,
    generateAndDownloadPdf,
    generateAndDownloadTaFormPdf,
    formatFileNameForDownload,
    createDownloadableFile,
  };
}

import { computed } from "vue";
import useChecks from "@/composables/checks";
import propertyPackTasks from "@/schemas/propertyPackTasks";
import useTransaction from "@/composables/transaction";

const { checks, filterFlagsByName } = useChecks();
const {
  hasPurchasedLandlordIdentityCheck,
  hasPurchasedMaterialInformationPack,
  hasPurchasedSellerIdentityCheck,
  isSellerContractReadyPurchaseRequested,
} = useTransaction();

const CONTRACT_READY_UPGRADE_USER_STEP = "upgrade";
const IDENTITY_USER_STEP = "identity";
const LISTING_QUESTIONS_USER_STEP = "listing";
const LETTINGS_LISTING_QUESTIONS_USER_STEP = "lettingsListing";
const MATERIAL_INFO_QUESTIONS_USER_STEP = "materialInfo";
const LEGAL_INFO_QUESTIONS_USER_STEP = "legalInfo";
const PROPERTY_QUESTIONS_USER_STEP = "propertyQuestions";
const LEASEHOLD_QUESTIONS_USER_STEP = "leaseholdQuestions";
const FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP = "fittingsAndContents";
const PROPERTY_LAWYER_USER_STEP = "addLawyer";

const categoryToOverlayMapping = {
  listing: "nts2023",
  "lettings-listing": "ntsl2023",
  "material-info": "baspiV4",
  "legal-info": "baspiV4",
  "property-questions": "ta6ed4",
  "leasehold-questions": "ta7ed3",
  "fittings-and-contents": "ta10ed3",
};

const ALL_USER_STEPS = {
  upgrade: {
    name: "Upgrade to Contract Ready",
    description: "",
    category: "seller-payment",
    initialRoute: {
      name: "setup.payment",
    },
    allowViewIfComplete: false,
  },
  identity: {
    name: "Verify your identity", // "Confirm your personal details" if no ID checks
    description: "A legal requirement for all sellers.", // modified to 'all landlords' for lettings
    duration: "(5 minutes)",
    category: "seller-info", // modified to 'landlord-info' for lettings
    initialRoute: {
      name: "participant.personal-details-seller",
    },
    allowViewIfComplete: true,
    showProgressMenu: true,
    // Needed at the moment as you can't really skip the personal details and go straight to IDV.
    disableProgressMenuLinks: true,
  },
  listing: {
    name: "Confirm upfront property information",
    description:
      "Your property can't legally be listed without it - to be completed as soon as possible.",
    pageLead:
      "Information required to be compliant with Trading Standards regulations, and get your property listed.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription: "",
    duration: "(5&#8211;10 minutes)",
    category: "listing",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.listing.start",
    },
    showProgressMenu: true,
  },
  lettingsListing: {
    name: "Confirm upfront property information",
    description:
      "Your property can't legally be listed without it - to be completed as soon as possible.",
    pageLead:
      "Information required to be compliant with Trading Standards regulations, and get your property listed.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription: "",
    duration: "(5&#8211;10 minutes)",
    category: "lettings-listing",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.lettings-listing.start",
    },
    showProgressMenu: true,
  },
  materialInfo: {
    name: "Property information",
    description: "Includes disputes, notices and environmental issues.",
    pageLead:
      "Vital information about the property to progress your sale quickly.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription:
      "Whether you are the legal owner of the property or representing them you should complete this form as though the questions are being answered by the legal owner and based, as far as possible, on their personal knowledge of the property. The information you provide must be accurate. You must complete this form to comply with the Consumer Protection from Unfair Trading Regulations 2008 during the marketing of the property. Under these regulations it is a legal requirement to disclose anything that would materially affect the ‘average person's' decision to buy the property.",
    duration: "(15&#8211;20 minutes)",
    category: "material-info",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.material-info.start",
    },
    showProgressMenu: true,
  },
  legalInfo: {
    name: "Legal information",
    description: "Information required for the legal part of the process.",
    pageLead:
      "This information is required for the legal process for a speedy completion. It&rsquo;ll help your property lawyer get your sale moving.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription:
      "Your property lawyer and your buyer's property lawyer and valuer will use this information as part of the legal process for selling your home and it will form part of the contract for sale. You must answer all questions or it will delay the legal process. You must not leave out information which could affect a buyer's decision to proceed. If any of the information changes before you complete the sale then you should let your estate agent and your property lawyer know about the changes, in writing, as soon as possible.",
    duration: "(10&#8211;15 minutes)",
    category: "legal-info",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.legal-info.start",
    },
    showProgressMenu: true,
  },
  propertyQuestions: {
    name: "Provide detailed property information",
    description: "Includes boundaries, alterations and rights etc",
    pageDescription: "",
    pageLead:
      "This section completed by the seller to supply the detailed information and documents which may be relied upon for the conveyancing process.",
    feedbackMessage:
      "The answers should be prepared by the person or persons who are named as owner on the deeds or Land Registry title or by the owner's legal representative(s) if selling under a power of attorney or grant of probate or representation. If there is more than one seller, you should prepare the answers together.",
    duration: "(20&#8211;30 minutes)",
    category: "property-questions",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.property-questions.start",
    },
    showProgressMenu: true,
  },
  leaseholdQuestions: {
    name: "Provide leasehold information",
    description:
      "Includes documents, lease contact details, service charges, etc",
    pageLead: "You need to provide information about your lease.",
    pageDescription: "",
    feedbackMessage:
      "The seller should provide all relevant documentation relating to the lease. This may include documents given to the seller when they purchased the property, or documents subsequently given to the seller by those managing the property.",
    duration: "(20&#8211;30 minutes)",
    category: "leasehold-questions",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.leasehold-questions.start",
    },
    showProgressMenu: true,
  },
  fittingsAndContents: {
    name: "Describe fittings, fixtures and contents",
    description: "What's included and not included in the sale",
    pageLead:
      "The aim of this section is to make clear to the buyer which items are included in the sale. It must be completed accurately by the seller as the answers may become part of the contract between the buyer and seller",
    pageDescription: "",
    feedbackMessage:
      "Where an item is excluded from the sale the seller may offer it for sale by specifying a price. The buyer can then decide whether to accept the seller's offer. A seller who specifies a price is responsible for negotiating the sale of that item directly with the buyer or through their estate agent.",
    duration: "(10&#8211;20 minutes)",
    category: "fittings-and-contents",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.fittings-and-contents.start",
    },
    showProgressMenu: true,
  },
  addLawyer: {
    name: "Share with your property lawyer",
    description:
      "Provide contact details so we can share the Contract Ready pack with them.",
    duration: "(2 minutes)",
    category: "sellers-conveyancer-info",
    initialRoute: {
      name: "participant.conveyancer.add",
    },
    showProgressMenu: false,
  },
};

const contractReadySellerPurchaseOrderFlag = computed(() => {
  const crFlag = filterFlagsByName(
    "contractReadySellerPurchaseOrder",
    checks.value
  );
  return crFlag?.length > 0 ? crFlag[0] : false;
});

const hasPurchasedContractReadyPack = computed(() => {
  // NB this won't work for EA purchase of CR pack
  // We do this as we can't rely on the transaction state which is where
  // the purchasedProducts are updated
  return contractReadySellerPurchaseOrderFlag?.value?.severity === "success";
});

const hasLeasehold = computed(() => {
  // leasehold-questions checks are only present if there is at least
  // one leasehold in the ownership state
  const leaseholdChecks = stepChecks(LEASEHOLD_QUESTIONS_USER_STEP);
  return leaseholdChecks?.length > 0;
});

const isLettings = computed(() => {
  // lettings-listing flags are only present if the transaction is a lettings one
  const lettingsChecks = stepChecks(LETTINGS_LISTING_QUESTIONS_USER_STEP);
  return lettingsChecks?.length > 0;
});

const updateIdentityStep = () => {
  if (isLettings.value) {
    ALL_USER_STEPS[IDENTITY_USER_STEP].category = "landlord-info";
    ALL_USER_STEPS[IDENTITY_USER_STEP].initialRoute = {
      name: "participant.personal-details-landlord",
    };

    if (hasPurchasedLandlordIdentityCheck.value) {
      ALL_USER_STEPS[IDENTITY_USER_STEP].description =
        "A legal requirement for all landlords.";
    } else {
      ALL_USER_STEPS[IDENTITY_USER_STEP].name = "Confirm your details";
      // Not the most useful copy, but added to "fill the space" to balance the UI.
      ALL_USER_STEPS[IDENTITY_USER_STEP].description =
        "Including your full name residential address.";
    }
  } else {
    if (!hasPurchasedSellerIdentityCheck.value) {
      ALL_USER_STEPS[IDENTITY_USER_STEP].name = "Confirm your details";
      // Not the most useful copy, but added to "fill the space" to balance the UI.
      ALL_USER_STEPS[IDENTITY_USER_STEP].description =
        "Including your full name residential address.";
    }
  }
};

const activeUserSteps = computed(() => {
  // this allows us to dynamically configure the steps displayed
  // depending on the options chose by the agent or changes in the
  // state of the property pack itself
  updateIdentityStep();

  let activeStepNames = [IDENTITY_USER_STEP];

  if (hasPurchasedMaterialInformationPack.value) {
    if (isLettings.value) {
      activeStepNames.push(LETTINGS_LISTING_QUESTIONS_USER_STEP);
    } else {
      activeStepNames.push(LISTING_QUESTIONS_USER_STEP);
    }
  }

  if (isSellerContractReadyPurchaseRequested.value) {
    activeStepNames.push(CONTRACT_READY_UPGRADE_USER_STEP);
    if (hasPurchasedContractReadyPack.value) {
      activeStepNames.push(PROPERTY_QUESTIONS_USER_STEP);
      if (hasLeasehold.value) {
        activeStepNames.push(LEASEHOLD_QUESTIONS_USER_STEP);
      }
      activeStepNames.push(
        FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
        PROPERTY_LAWYER_USER_STEP
      );
    }
  }

  const steps = activeStepNames.map((stepName) => {
    return { stepName, ...ALL_USER_STEPS[stepName] };
  });

  return steps;
});

const orderFlagsByUiSchema = (unorderedFlags) => {
  const orderedFlags = [...unorderedFlags].sort((flag, comparisonFlag) => {
    const flagIndex = propertyPackTasks.findIndex(
      ({ path, category }) => path === flag.path && category === flag.category
    );
    const comparisonFlagIndex = propertyPackTasks.findIndex(
      ({ path, category }) =>
        path === comparisonFlag.path && category === comparisonFlag.category
    );
    return flagIndex - comparisonFlagIndex;
  });
  return orderedFlags;
};

const stepChecks = (stepName) => {
  const filteredChecks = (checks.value || []).filter(
    (check) => check.category === ALL_USER_STEPS[stepName].category
  );

  return orderFlagsByUiSchema(filteredChecks);
};

const getFirstCheck = (stepName) => {
  const checks = stepChecks(stepName);
  return checks[0];
};

const isFirstCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const firstCheck = checks[0];
  return firstCheck?.path === path;
};

const isLastCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const lastCheck = checks[checks.length - 1];
  return lastCheck?.path === path;
};

const getNextCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex((check) => check.path === path);
  if (currentCheckIndex === checks.length - 1) return undefined;
  if (currentCheckIndex === -1) return undefined;
  return checks[currentCheckIndex + 1];
};

const getPreviousCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex((check) => check.path === path);
  if (currentCheckIndex < 0) return undefined;
  return checks[currentCheckIndex - 1];
};

const incompleteChecks = (checks) => {
  return (checks || []).filter((check) => check.severity !== "success");
};

const getNextIncompleteCheck = (currentPath, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex(
    (check) => check.path === currentPath
  );
  return checks
    .slice(currentCheckIndex + 1)
    .find(({ severity }) => severity !== "success");
};

const isStepComplete = (stepName) => {
  const isComplete = incompleteChecks(stepChecks(stepName)).length === 0;
  return isComplete;
};

const percentStepComplete = (stepName) => {
  const allChecks = stepChecks(stepName);
  const total = allChecks.length;
  const complete = total - incompleteChecks(allChecks).length;
  const fraction = complete / total;

  if (isNaN(fraction)) return 0;
  if (fraction > 1) return 100;
  if (fraction < 0) return 0;
  return fraction * 100;
};

const hasStartedStep = (stepName) => percentStepComplete(stepName) > 0;

const activeStep = () => {
  // return the first step which has incomplete items
  return activeUserSteps.value.findIndex(
    (step) => !isStepComplete(step.stepName)
  );
};

const showProgressMenu = (stepName) =>
  !!ALL_USER_STEPS[stepName]?.showProgressMenu;

const hasStepTag = (stepName) => !!ALL_USER_STEPS[stepName]?.tag;

const isAllStepsCompleted = computed(() =>
  activeUserSteps.value.every((step) => isStepComplete(step.stepName))
);

const getHelpTopics = (path) => {
  const helpTopics = [
    "HowDoIAnswer",
    "DoNotKnow",
    "CanISkipAQuestion",
    "WhatIdIDontHaveADocument",
    "WhoCanSeeTheseAnswers",
  ];
  const extraHelpTopicPaths = {
    "/propertyPack/ownership": "OwnershipTypes",
  };

  if (extraHelpTopicPaths[path]) helpTopics.push(extraHelpTopicPaths[path]);

  return helpTopics;
};

const isStepDisabled = () => {
  //stepName) => {
  return false;
  // if (!contractReadySellerPurchaseOrderFlag.value) return false;

  // if (stepName === CONTRACT_READY_UPGRADE_USER_STEP) return false;

  // const hasPurchasedContractReadyPack =
  //   contractReadySellerPurchaseOrderFlag.value?.collectorState ===
  //   collectorState.SETTLED;

  // return !hasPurchasedContractReadyPack;
};

export default function useUserTasks() {
  return {
    ALL_USER_STEPS,
    CONTRACT_READY_UPGRADE_USER_STEP,
    FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
    IDENTITY_USER_STEP,
    LEASEHOLD_QUESTIONS_USER_STEP,
    LEGAL_INFO_QUESTIONS_USER_STEP,
    LETTINGS_LISTING_QUESTIONS_USER_STEP,
    LISTING_QUESTIONS_USER_STEP,
    MATERIAL_INFO_QUESTIONS_USER_STEP,
    PROPERTY_LAWYER_USER_STEP,
    PROPERTY_QUESTIONS_USER_STEP,
    activeStep,
    activeUserSteps,
    categoryToOverlayMapping,
    getFirstCheck,
    getHelpTopics,
    getNextCheck,
    getNextIncompleteCheck,
    getPreviousCheck,
    hasLeasehold,
    hasPurchasedContractReadyPack,
    hasStartedStep,
    hasStepTag,
    incompleteChecks,
    isAllStepsCompleted,
    isFirstCheck,
    isLastCheck,
    isLettings,
    isStepComplete,
    isStepDisabled,
    orderFlagsByUiSchema,
    percentStepComplete,
    showProgressMenu,
    stepChecks,
  };
}

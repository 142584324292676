/**
 * It's possible to have more than one principal conveyancer for the seller,
 * but that shouldn't really happen. So this works under the assumption
 * that there is only one conveyancer for the seller.
 */
import useChecks from "@/composables/checks";
import useParticipants from "@/composables/participants";
import useUserProfile from "@/composables/userProfile";
import useVerifiedClaims from "@/composables/verifiedClaims";
import { reactive, ref, computed } from "vue";
import { required, helpers, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const { createVerifiedClaim, addVerifiedClaim } = useVerifiedClaims();
const { filterFlagsByName } = useChecks();
const { fullName } = useUserProfile();
const { participantRoles, getParticipantsByRole } = useParticipants();

const isSavingConveyancer = ref(false);
const conveyancerErrorMessage = ref("");

const hasConveyancer = computed(() => {
  const conveyancerFlags = filterFlagsByName(["participantSellerConveyancer"]);

  return conveyancerFlags.length === 0;
  // TODO: Update the flags to change this from error to success to match
  // how the other checks behave.
  // return (
  //   conveyancerFlags.length > 0 &&
  //   conveyancerFlags.every((flag) => flag?.severity === "success")
  // );
});

const conveyancerParticipants = computed(() =>
  getParticipantsByRole(participantRoles.SELLER_CONVEYANCER)
);

const addConveyancerFormData = reactive({
  name: {
    firstName: "",
    lastName: "",
  },
  email: "",
  organisation: "",
});

const addConveyancerValidationRules = {
  name: {
    firstName: {
      required: helpers.withMessage("Enter their first name", required),
    },
    lastName: {
      required: helpers.withMessage("Enter their last name", required),
    },
  },
  email: {
    required: helpers.withMessage("Enter their email", required),
    email: helpers.withMessage("Their email isn&rsquo;t valid", email),
  },
  organisation: {
    required: helpers.withMessage(
      "Enter the name of their organisation or law firm",
      required
    ),
  },
};

const addConveyancerValidation = useVuelidate(
  addConveyancerValidationRules,
  addConveyancerFormData
);

const saveConveyancer = async (transactionId) => {
  isSavingConveyancer.value = true;

  try {
    const conveyancerClaim = createVerifiedClaim({
      path: `/participants/-`,
      voucherName: fullName.value,
      data: {
        name: {
          firstName: addConveyancerFormData.name.firstName,
          lastName: addConveyancerFormData.name.lastName,
        },
        organisation: addConveyancerFormData.organisation,
        email: addConveyancerFormData.email,
        role: participantRoles.SELLER_CONVEYANCER,
      },
    });
    await addVerifiedClaim(transactionId, conveyancerClaim);
    return true;
  } catch (error) {
    conveyancerErrorMessage.value = error.message;
    return false;
  } finally {
    isSavingConveyancer.value = false;
  }
};

export default function useConveyancer() {
  return {
    addConveyancerFormData,
    addConveyancerValidation,
    addConveyancerValidationRules,
    conveyancerErrorMessage,
    conveyancerParticipants,
    hasConveyancer,
    isSavingConveyancer,
    saveConveyancer,
  };
}

export default Object.freeze({
  "invite/410":
    "Your invite has expired. Please get in touch with your agent to get a new one.",
  "invite/404":
    "An invite could not be found. Please get in touch with your agent to request a new one.",
  "invite/401":
    "You've signed in with an email that isn't associated with your invite. Please sign out and try again using the email address that received the invite.",
  "invite/409": "The invite has already been accepted.",
  "invite/500":
    "There's been a problem accepting the invite. Please try again or contact us for assistance.",
  "add-executive-to-transaction/409":
    "You are already associated with this transaction.",
});

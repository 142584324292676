/**
 * Helper file to abstract some methods away from an
 * ever-growing PDTF composable.
 */
import participantRoles from "../enums/participant-roles";
import { usePDTF } from "@/composables/pdtf";
import { computed } from "vue";

const { pdtfState } = usePDTF();

const getParticipants = (state) => {
  const participants = state?.participants;

  if (!Array.isArray(participants)) return [];

  return participants.map((participant, index) => ({
    ...participant,
    index,
  }));
};

/**
 * Add the schema index as it's used a lot, and it's quicker than
 * interrogating it every time.
 */
const participants = computed(() => getParticipants(pdtfState.value));

/**
 * Includes regression support for the old company name.
 * Can be removed when any data relying on it has been migrated.
 */
const findParticipantByUserId = (userId) => {
  const match = participants.value.find(
    (participant) => participant?.externalIds?.Moverly === userId
  );

  return match;
};

const getParticipantsByRole = (roles, stateToUse) => {
  const rolesToFind = Array.isArray(roles) ? roles : [roles];
  const participantsToSearch = stateToUse || participants.value;

  if (!Array.isArray(participantsToSearch)) return [];

  const filteredParticipants = participantsToSearch.filter((participant) =>
    rolesToFind.includes(participant.role)
  );

  return filteredParticipants;
};

const formatVoucherName = (participantName) => {
  const concatentatedName = participantName.middleName
    ? `${participantName.firstName} ${participantName.middleName} ${participantName.lastName}`
    : `${participantName.firstName} ${participantName.lastName}`;
  return concatentatedName;
};

const formatParticpantSchemaPath = (participant) => {
  // Don't forget, null is cast as 0.
  const participantHasIndex =
    participant?.index !== null && !isNaN(Number(participant?.index));

  return participantHasIndex
    ? `/participants/${participant.index}`
    : `/participants/-`;
};

const formatExternalIds = (userId) => {
  return {
    Moverly: userId,
  };
};

const isParticipantForTransaction = (
  userProfileTransactions,
  transactionId
) => {
  if (!userProfileTransactions) return false;

  return userProfileTransactions
    .map((rel) => rel.transactionId)
    .includes(transactionId);
};

export default function useParticipants() {
  return {
    findParticipantByUserId,
    formatExternalIds,
    formatParticpantSchemaPath,
    formatVoucherName,
    getParticipants,
    getParticipantsByRole,
    isParticipantForTransaction,
    participantRoles,
    participants,
  };
}

import useUserProfile from "@/composables/userProfile";

const requiresUserProfile = async (to, from, next) => {
  const { fetchUserProfile, userProfile } = useUserProfile();

  // I think this is a lazy load check...
  if (Object.keys(userProfile.value).length === 0) {
    await fetchUserProfile();
  }

  next();
};

export default requiresUserProfile;

import Honeybadger from "@honeybadger-io/js";
import participantRoles from "@/enums/participant-roles";
import useAuth from "@/composables/auth";
import useInvites from "@/composables/invites";
import useUserProfile from "@/composables/userProfile";
import useVerifiedClaims from "@/composables/verifiedClaims";
import { usePDTF, usePDTFUtils } from "@/composables/pdtf";

const { fetchTransaction, getStateByPath } = usePDTF();
const { isLetting } = usePDTFUtils();
const { fetchVerifiedClaims } = useVerifiedClaims();
const { isLandlordForTransaction, isSellerForTransaction } = useUserProfile();
const { userId } = useAuth();

const isTheFirstParticipantInTransaction = () => {
  const participants = getStateByPath("/participants");

  // A non-existent path returns an empty object, so check before iterating.
  if (!Array.isArray(participants)) return true;

  const marketParticipantRole = isLetting()
    ? participantRoles.LANDLORD
    : participantRoles.SELLER;

  const listOfPropertyMarketParticipants = participants.reduce(
    (totalSellers, participant) => {
      if (participant.role === marketParticipantRole) {
        return [...totalSellers, participant];
      }

      return totalSellers;
    },
    []
  );

  // Was previously zero, but now that a participant is created at the point
  // of invite acceptance, we should check for 1.
  const isFirstParticipant = listOfPropertyMarketParticipants.length === 1;

  return isFirstParticipant;
};

const isSellerOrLandlordForTransaction = (transactionId) => {
  return (
    isSellerForTransaction(transactionId) ||
    isLandlordForTransaction(transactionId)
  );
};

const redirectBasedOnInviteStatus = async (to, from, next) => {
  const { token } = to.query;
  const { transactionId } = to.params;
  const { acceptInvite } = useInvites();
  const { fetchUserProfile } = useUserProfile();

  try {
    await acceptInvite({
      inviteeUserId: userId.value,
      transactionId,
      token,
    });
  } catch (ex) {
    const status = ex?.response?.status || "500";

    Honeybadger.notify(ex, {
      message: "Accept invite has failed.",
      name: "redirectBasedOnInviteStatus",
      params: {
        transactionId,
        userId: userId.value,
      },
    });

    next({
      name: "invites.error",
      params: { transactionId },
      query: {
        status: `invite/${status}`,
      },
    });
  }

  try {
    /**
     * Load the state to redirect the user based on whether
     * they are the first (primary) Seller, or a Co-seller.
     *
     * Fetching the user profile to ensure we get the now updated
     * user data for the transaction.
     */
    await Promise.all([
      fetchTransaction(transactionId),
      fetchVerifiedClaims(transactionId),
      fetchUserProfile(),
    ]).catch((ex) => {
      Honeybadger.notify(ex, {
        message:
          "Failed to load transaction, claims, or user profile after invite acceptance.",
        name: "redirectBasedOnInviteStatus",
        params: {
          transactionId,
          userId: userId.value,
        },
      });
      next({
        name: "invites.error",
        params: { transactionId },
      });
    });

    if (
      isSellerOrLandlordForTransaction(transactionId) &&
      isTheFirstParticipantInTransaction()
    ) {
      return next({
        name: "property.view",
        params: { transactionId },
      });
    }

    // For either co-seller or buyer, send them to the same restricted flow.
    return next({
      name: "participants.identity.introduction",
      params: { transactionId },
    });
  } catch (ex) {
    console.error(ex);
    const status = ex?.response?.status || "";

    return next({
      name: "invites.error",
      params: { transactionId },
      query: {
        status,
        token,
      },
    });
  }
};

export default redirectBasedOnInviteStatus;

/**
 * This flow contains a page to invite co-sellers. It will eventually be moved out
 * into another flow that will handle owners/co-sellers in a more comprehensive way.
 * Removing the props will handle any regression issue when this happens.
 */
const applyParticipantRoleProp = (route) => ({
  participantType: route.query.type || "",
});

export default [
  // I think this is regression handling to manage old check routes.
  {
    name: "participant.personal-details-seller",
    path: "manage/participants/add-seller",
    redirect: () => {
      return {
        name: "participants.identity.introduction",
        query: { type: "primary-seller" },
      };
    },
  },
  {
    name: "participant.personal-details-landlord",
    path: "manage/participants/add-landlord",
    redirect: () => {
      return {
        name: "participants.identity.introduction",
        query: { type: "landlord" },
      };
    },
  },
  {
    name: "participants.identity.introduction",
    path: "participants/identity/introduction",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityIntroduction.vue"
      ),
    props: applyParticipantRoleProp,
    meta: {
      title: "Introduction",
    },
  },
  {
    name: "participants.identity.about",
    path: "participants/identity/about",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityPersonalDetails.vue"
      ),
    props: applyParticipantRoleProp,
    meta: {
      title: "About you",
    },
  },
  {
    name: "participants.identity.invite",
    path: "participants/identity/invite",
    component: () => import("../../views/identity/IdentityInviteCoSellers.vue"),
    props: applyParticipantRoleProp,
    meta: {
      title: "Additional sellers",
    },
  },
  {
    name: "participants.identity.verify",
    path: "participants/identity/verify",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityVerify.vue"
      ),
    props: applyParticipantRoleProp,
    meta: {
      title: "Verify",
    },
  },
  {
    name: "participants.identity.complete",
    path: "participants/identity/complete",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityComplete.vue"
      ),
    props: applyParticipantRoleProp,
    meta: {
      title: "Complete",
    },
  },
];

import axios from "axios";
import disposalType from "@/enums/disposal";
import marketingTenure from "@/enums/marketing-tenure";
import mediaType from "@/enums/media-type";
import { priceQualifier } from "@/enums/pdtf";
import { reapitPriceQualifier } from "@/enums/reapit";
import useAuth from "@/composables/auth";
import { MOVEREADY_INTEGRATION_API_URL } from "@/config";
import { ReapitConnectBrowserSession } from "@reapit/connect-session";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import { usePDTF } from "@/composables/pdtf";
import transactionStates from "@/enums/transaction-states";
import participantRoles from "@/enums/participant-roles";
import {
  REAPIT_CLIENT_ID,
  REAPIT_CONNECT_APPLICATION_TIMEOUT,
  REAPIT_CONNECT_USER_POOL_ID,
  REAPIT_CONNECT_LOGIN_REDIRECT_PATH,
  REAPIT_CONNECT_LOGOUT_REDIRECT_PATH,
  REAPIT_CONNECT_OAUTH_URL,
  REAPIT_PROPERTY_URL,
} from "@/config";
import {
  reapitPropertyStyle,
  reapitTenureType,
  reapitDisposal,
  reapitMediaType,
  reapitRentFrequency,
  reapitDepositType,
  reapitMarketingMode,
} from "@/enums/reapit";
import { rentFrequency } from "@/enums/pdtf";
import userText from "@/composables/text";
import Honeybadger from "@honeybadger-io/js";

const { format } = userText();
const fetchReapitPropertyError = ref("");
const isFetchingReapitVendors = ref(false);
const isFetchingReapitLandlords = ref(false);
const isResolvingAddress = ref(false);
const reapitProperty = ref(null);
const reapitPropertyImages = ref(null);
const reapitVendors = ref([]);
const reapitLandlords = ref([]);
const resolveAddressError = ref("");
const { getAccessToken, signUserInWithCustomToken } = useAuth();
const { transactionId, transactionError } = usePDTF();
const reapitConnectBrowserSessionInstance = ref(null);
let reapitConnectInstance;

const loginWithReapitAuth = async (reapitAccessToken, loginIdentity) => {
  const appCheckToken = await getAppCheckToken();

  try {
    const loginResponse = await axios.get(
      `${MOVEREADY_INTEGRATION_API_URL}/reapit/auto-login`,
      {
        params: {
          token: reapitAccessToken,
          clientId: loginIdentity.clientId,
          officeId: loginIdentity.officeId,
          loginIdentity,
        },
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    await signUserInWithCustomToken(loginResponse.data);
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to auto-login a Reapit user",
      name: "reapit.js",
      params: {
        loginIdentity,
      },
    });
    throw ex;
  }
};

const formatReapitAddress = (address) => {
  const formattedAddressLines = [];

  if (address.buildingName) {
    formattedAddressLines.push(address.buildingName);
  }

  if (address.buildingNumber) {
    formattedAddressLines.push(
      `${address.buildingNumber} ${address.line1}`.trim()
    );
  } else {
    formattedAddressLines.push(address.line1);
  }

  // Reapit has a buildingNumber and line1 that when concatenated are the same as line2. Not sure yet if this is consistent with buildingName.
  if (!formattedAddressLines.includes(address.line2)) {
    formattedAddressLines.push(address.line2);
  }

  if (address.line3) {
    formattedAddressLines.push(address.line3);
  }

  if (address.line4) {
    formattedAddressLines.push(address.line4);
  }

  if (address.postcode) {
    formattedAddressLines.push(address.postcode);
  }

  const removeEmptyAddressLines = (line) => line;

  return formattedAddressLines.filter(removeEmptyAddressLines);
};

const reapitGlobals = window["__REAPIT_MARKETPLACE_GLOBALS__"] || {};

const mapReapitPriceQualifierToSchema = (reapitPriceQualifer) => {
  const qualifierMap = {
    [reapitPriceQualifier.GUIDE_PRICE]: priceQualifier.GUIDE_PRICE,
    [reapitPriceQualifier.OFFERS_IN_REGION]: priceQualifier.OFFERS_IN_REGION,
    [reapitPriceQualifier.OFFERS_IN_EXCESS]: priceQualifier.OFFERS_IN_EXCESS,
    [reapitPriceQualifier.FIXED_PRICE]: priceQualifier.FIXED_PRICE,
  };

  return qualifierMap[reapitPriceQualifer] || null;
};

const mapReapitTenureTypeToSchema = (tenureType) => {
  const tenureMap = {
    [reapitTenureType.COMMONHOLD]: marketingTenure.COMMONHOLD,
    [reapitTenureType.FREEHOLD]: marketingTenure.FREEHOLD,
    [reapitTenureType.LEASEHOLD]: marketingTenure.LEASEHOLD,
    [reapitTenureType.SHARE_OF_FREEHOLD]: marketingTenure.SHARE_OF_FREEHOLD,
  };

  return tenureMap[tenureType] || null;
};

const mapReapitDisposalToSchema = (disposal) => {
  const disposalMap = {
    [reapitDisposal.AUCTION]: disposalType.AUCTION,
    [reapitDisposal.PRIVATE_TREATY]: disposalType.PRIVATE_TREATY,
  };

  return disposalMap[disposal] || null;
};

const isAppInDektopMode = () => {
  return !!window["__REAPIT_MARKETPLACE_GLOBALS__"];
};

const reapitConnectBrowserSession = async () => {
  if (reapitConnectBrowserSessionInstance.value)
    return reapitConnectBrowserSessionInstance.value;

  reapitConnectInstance = new ReapitConnectBrowserSession({
    connectClientId: REAPIT_CLIENT_ID,
    connectOAuthUrl: REAPIT_CONNECT_OAUTH_URL,
    connectUserPoolId: REAPIT_CONNECT_USER_POOL_ID,
    connectLoginRedirectPath: REAPIT_CONNECT_LOGIN_REDIRECT_PATH,
    connectLogoutRedirectPath: REAPIT_CONNECT_LOGOUT_REDIRECT_PATH,
    connectApplicationTimeout: REAPIT_CONNECT_APPLICATION_TIMEOUT,
  });
  reapitConnectBrowserSessionInstance.value =
    await reapitConnectInstance.connectSession();

  return reapitConnectBrowserSessionInstance.value;
};

const reapitConnectLogout = () => {
  if (reapitConnectInstance) {
    reapitConnectInstance.connectLogoutRedirect();
  }
};

const fetchReapitProperty = async (reapitPropertyId) => {
  try {
    const { accessToken } = await reapitConnectBrowserSession();
    const response = await axios.get(
      `https://platform.reapit.cloud/properties/${reapitPropertyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "api-version": "2020-01-31",
        },
      }
    );
    reapitProperty.value = response.data;
  } catch (ex) {
    fetchReapitPropertyError.value = ex?.message || ex;
    Honeybadger.notify(ex, {
      message: "Failed to fetch a Reapit property",
      name: "reapit.js",
      params: {
        reapitPropertyId,
      },
    });
  }
};

const fetchReapitPropertyImages = async (reapitPropertyId) => {
  const { accessToken } = await reapitConnectBrowserSession();

  const params = new URLSearchParams();
  params.append("type", reapitMediaType.PHOTOGRAPH);
  params.append("type", reapitMediaType.FLOORPLAN);
  params.append("pageSize", 100); // 100 is the max page size.
  params.append("propertyId", reapitPropertyId);

  try {
    const response = await axios.get(
      `https://platform.reapit.cloud/propertyImages`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "api-version": "2020-01-31",
        },
      }
    );
    reapitPropertyImages.value = response.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch a Reapit property's images",
      name: "reapit.js",
      params: {
        reapitPropertyId,
        reapitProperty: reapitProperty.value,
      },
    });
    throw ex;
  }
};

const fetchReapitVendors = async (reapitPropertyId) => {
  isFetchingReapitVendors.value = true;
  const { accessToken } = await reapitConnectBrowserSession();

  try {
    const response = await axios.get(
      `https://platform.reapit.cloud/vendors/${reapitPropertyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "api-version": "2020-01-31",
        },
      }
    );
    reapitVendors.value = response.data?.related || [];
    isFetchingReapitVendors.value = false;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch a Reapit property's vendors",
      name: "reapit.js",
      params: {
        reapitPropertyId,
        reapitProperty: reapitProperty.value,
      },
    });
    throw ex;
  }
};

const fetchReapitLandlords = async (reapitPropertyId) => {
  await fetchReapitProperty(reapitPropertyId);
  const landlordId = reapitProperty.value?.letting?.landlordId;

  if (!landlordId) return;

  isFetchingReapitLandlords.value = true;
  const { accessToken } = await reapitConnectBrowserSession();

  try {
    const response = await axios.get(
      `https://platform.reapit.cloud/landlords/${landlordId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "api-version": "2020-01-31",
        },
      }
    );
    reapitLandlords.value = response.data?.related || [];
    isFetchingReapitLandlords.value = false;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch a Reapit property's landlords",
      name: "reapit.js",
      params: {
        reapitPropertyId,
        reapitProperty: reapitProperty.value,
      },
    });
    throw ex;
  }
};

const mapReapitAddressToSchema = (reapitAddress) => {
  const address = {
    line2: reapitAddress.line2,
    line3: reapitAddress.line3,
    postcode: reapitAddress.postcode,
  };
  const line1Parts = [];

  if (reapitAddress.buildingName) {
    line1Parts.push(reapitAddress.buildingName);
  }

  if (reapitAddress.buildingNumber) {
    line1Parts.push(
      `${reapitAddress.buildingNumber} ${reapitAddress.line1}`.trim()
    );
  } else {
    line1Parts.push(reapitAddress.line1);
  }

  return { ...address, line1: line1Parts.join(", ") };
};

const reapitVendorsAsParticipants = computed(() =>
  formatReapitVendorsAsParticipants(reapitVendors.value)
);

const formatReapitVendorsAsParticipants = (vendors = []) => {
  return vendors.map((vendor) =>
    formatReapitContactAsParticipant(vendor, participantRoles.SELLER)
  );
};

const reapitLandlordsAsParticipants = computed(() =>
  formatReapitLandlordsAsParticipants(reapitLandlords.value)
);

const formatReapitLandlordsAsParticipants = (landlords = []) => {
  return landlords.map((landlord) =>
    formatReapitContactAsParticipant(landlord, participantRoles.LANDLORD)
  );
};

const formatReapitContactAsParticipant = (reapitContact, role) => {
  const participant = {
    name: {
      firstName: reapitContact.forename,
      middleName: "",
      lastName: reapitContact.surname,
    },
    role,
    phone: reapitContact.mobilePhone,
    email: reapitContact.email,
    externalIds: {
      Reapit: reapitContact.id,
    },
  };

  if (reapitContact.primaryAddress) {
    participant.address = mapReapitAddressToSchema(
      reapitContact.primaryAddress
    );
  }

  if (reapitContact.dateOfBirth) {
    participant.dateOfBirth = reapitContact.dateOfBirth;
  }

  return participant;
};

const createReapitTransaction = async ({ prpCode }) => {
  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    const { loginIdentity } = reapitConnectBrowserSessionInstance.value;
    const response = await axios.post(
      `${MOVEREADY_INTEGRATION_API_URL}/reapit/transactions`,
      { reapitPrpCode: prpCode, reapitAccountId: loginIdentity.clientId },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    if (response.data?.transactionId) {
      transactionId.value = response.data.transactionId;
      transactionError.value = null;
    } else {
      transactionId.value = null;
      throw new Error("Missing transaction Id");
    }
  } catch (ex) {
    transactionError.value = ex;
    Honeybadger.notify(ex, {
      message: "Failed to create a Reapit transaction",
      name: "reapit.js",
      params: {
        prpCode,
        reapitProperty: reapitProperty.value,
      },
    });
    throw ex;
  }
};

const findTransactionIdByReapitPropertyCode = async (prpCode) => {
  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    const response = await axios.get(
      `${MOVEREADY_INTEGRATION_API_URL}/reapit/transactions/${prpCode}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    return response.data.id;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to find a transaction by Reapit prpCode",
      name: "reapit.js",
      params: {
        prpCode,
        reapitProperty: reapitProperty.value,
      },
    });
    return null;
  }
};

const mapReapitMediaTypeToSchema = (mediaTypeToMap) => {
  const map = {
    [reapitMediaType.PHOTOGRAPH]: mediaType.IMAGE,
    [reapitMediaType.FLOORPLAN]: mediaType.FLOORPLAN,
    [reapitMediaType.MAP]: mediaType.IMAGE,
    [reapitMediaType.EPC]: mediaType.EPC,
  };

  return map[mediaTypeToMap] || null;
};

const mapReaitRentFrequencyToSchema = (frequency) => {
  const map = {
    [reapitRentFrequency.WEEKLY]: rentFrequency.WEEKLY,
    [reapitRentFrequency.MONTHLY]: rentFrequency.MONTHLY,
    [reapitRentFrequency.ANNUALLY]: rentFrequency.YEARLY,
  };

  return map[frequency] || null;
};

/**
 * In Reapit the `amount` is either a currency value, or a multiplier for a type
 * that is either number of weeks or months rent.
 */
const mapReapitDespositToSchema = (
  depositType,
  amount,
  rent,
  rentFrequency
) => {
  if (depositType === reapitDepositType.FIXED && amount && !isNaN(amount)) {
    return amount;
  }

  if (
    depositType === reapitDepositType.WEEKS &&
    amount &&
    rent &&
    rentFrequency === reapitRentFrequency.WEEKLY
  ) {
    return rent * amount;
  }

  if (
    depositType === reapitDepositType.MONTHS &&
    amount &&
    rent &&
    rentFrequency === reapitRentFrequency.MONTHLY
  ) {
    return rent * amount;
  }

  return null;
};

const mapReapitPropertyToSchemaStatusSaleOrLet = (reapitProperty) => {
  const marketingMode = reapitProperty?.marketingMode;

  if (marketingMode === reapitMarketingMode.SELLING) {
    return transactionStates.FOR_SALE;
  }

  if (marketingMode === reapitMarketingMode.LETTING) {
    return transactionStates.TO_LET;
  }

  return null;
};

const getReapitExternalUrl = (externalId) => {
  if (!REAPIT_PROPERTY_URL) return null;

  return format(REAPIT_PROPERTY_URL, externalId);
};

export default function useReapit() {
  return {
    createReapitTransaction,
    fetchReapitLandlords,
    fetchReapitProperty,
    fetchReapitPropertyError,
    fetchReapitPropertyImages,
    fetchReapitVendors,
    findTransactionIdByReapitPropertyCode,
    formatReapitAddress,
    formatReapitLandlordsAsParticipants,
    formatReapitVendorsAsParticipants,
    getReapitExternalUrl,
    isAppInDektopMode,
    isResolvingAddress,
    loginWithReapitAuth,
    mapReaitRentFrequencyToSchema,
    mapReapitAddressToSchema,
    mapReapitDespositToSchema,
    mapReapitDisposalToSchema,
    mapReapitMediaTypeToSchema,
    mapReapitPriceQualifierToSchema,
    mapReapitPropertyToSchemaStatusSaleOrLet,
    mapReapitTenureTypeToSchema,
    reapitConnectBrowserSession,
    reapitConnectBrowserSessionInstance,
    reapitConnectLogout,
    reapitDisposal,
    reapitGlobals,
    reapitLandlords,
    reapitLandlordsAsParticipants,
    reapitProperty,
    reapitPropertyImages,
    reapitPropertyStyle,
    reapitVendors,
    reapitVendorsAsParticipants,
    resolveAddressError,
  };
}

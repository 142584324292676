import axios from "axios";
import inviteStatus from "@/enums/invite-status";
import inviteStatusLabels from "@/enums/invite-status-label";
import participantRoles from "@/enums/participant-roles";
import useAuth from "@/composables/auth";
import useEnumUtils from "@/enums/utils";
import { MOVEREADY_USER_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken } = useAuth();
const invites = ref([]);
const isFindingInvites = ref(false);

const findInvitesByTransaction = async (transactionId, inviterUserId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFindingInvites.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    invites.value = response?.data || [];
    // Returning for regression fix.
    return response?.data || [];
  } catch (ex) {
    // A 404 is no invites, but return an empty array instead.
    if (ex?.response?.status === 404) {
      invites.value = [];
      return [];
    }

    throw ex;
  } finally {
    isFindingInvites.value = false;
  }
};

const findInvites = (transactionIdCollection, inviterUserId) => {
  const requests = [];
  transactionIdCollection.forEach((transactionId) => {
    requests.push(findInvitesByTransaction(transactionId, inviterUserId));
  });

  return Promise.all(requests);
};

const createInvite = async ({
  transactionId,
  inviterUserId,
  inviteeEmail,
  inviteeFirstName,
  inviteeLastName,
  inviteeRole,
  inviteePhone,
}) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  const inviteUrlsByRole = {
    [participantRoles.BUYER]: `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}/buyer`,
    [participantRoles.SELLER]: `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}/seller`,
    [participantRoles.LANDLORD]: `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}/landlord`,
    [participantRoles.TENANT]: `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}/tenant`,
  };

  const { getKeyByValue } = useEnumUtils();

  const inviteeRoleAsEnumKey = getKeyByValue(inviteeRole, participantRoles);

  await axios.post(
    inviteUrlsByRole[inviteeRole],
    {
      email: inviteeEmail,
      role: inviteeRoleAsEnumKey,
      inviteeFirstName,
      inviteeLastName,
      phone: inviteePhone,
    },
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
};

const createClientInvite = async ({
  transactionId,
  inviterUserId,
  inviteeEmail,
  inviteeFirstName,
  inviteeLastName,
  inviteeRole,
  inviteePhone,
}) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  const { getKeyByValue } = useEnumUtils();
  const inviteeRoleAsEnumKey = getKeyByValue(inviteeRole, participantRoles);
  await axios.post(
    `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}/client`,
    {
      email: inviteeEmail,
      role: inviteeRoleAsEnumKey,
      inviteeFirstName,
      inviteeLastName,
      phone: inviteePhone,
    },
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
};
const isDeletingInvite = ref(false);
const deleteInvite = async (inviteId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isDeletingInvite.value = true;
  try {
    await axios.delete(`${MOVEREADY_USER_API_URL}/invites/${inviteId}`, {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    });
  } catch (ex) {
    console.error(ex);
    throw ex;
  } finally {
    isDeletingInvite.value = false;
  }
};

const acceptInvite = async ({ inviteeUserId, transactionId, token }) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.patch(
      `${MOVEREADY_USER_API_URL}/invites/users/${inviteeUserId}/transactions/${transactionId}/token/${token}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    // A 409 means that the invite has been accepted.
    if (ex?.response?.status === 409) {
      return ex.response;
    }

    throw ex;
  }
};

export default function useInvites() {
  return {
    // Regression fix, remove when we can.
    acceptInvite,
    createClientInvite,
    createInvite,
    deleteInvite,
    findInvites,
    findInvitesByTransaction,
    inviteStatus,
    inviteStatusLabels,
    inviteStatuses: inviteStatus,
    invites,
    isDeletingInvite,
    isFindingInvites,
  };
}

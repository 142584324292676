import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_FILE_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken } = useAuth();

const formatTitleFileName = (formattedFileName) => {
  // Title number format is 2 or 3 letters followed by a set of numbers.
  const titleNumberRegEx = new RegExp("([A-Z]{2,3}[0123456789]+)", "g");
  const regExResult = titleNumberRegEx.exec(formattedFileName);

  if (!regExResult) return formattedFileName;
  // eslint-disable-next-line
  const [wholeMatch, titleNumberGroup] = regExResult;

  const lowerCaseFormattedFileName = formattedFileName.toLowerCase();

  if (lowerCaseFormattedFileName.includes("title plan - ")) {
    return `Title plan for ${titleNumberGroup}`;
  }

  if (lowerCaseFormattedFileName.includes("register -")) {
    return `Title register ${titleNumberGroup}`;
  }

  if (lowerCaseFormattedFileName.includes("official-copy-lease")) {
    return `Lease for ${titleNumberGroup}`;
  }

  // Remove file extension
  return formattedFileName.split(".")[0];
};

const formatUploadsAsAttachments = (uploadedFiles) => {
  const attachments = uploadedFiles.map((file) => {
    const verifiedClaimAttachment = {
      desc: file.displayName,
      url: file.signedUrlWithFileId,
      algorithm: "md5",
      hash: file.md5Hash,
    };

    if (file.exactPath) {
      verifiedClaimAttachment.pdtfSchemaPath = file.exactPath;
    }

    return verifiedClaimAttachment;
  });

  return attachments;
};

const getFileIdFromQueryString = (url) => {
  if (!url) return null;

  return new URL(url).searchParams.get("fileId");
};

export default function useUpload() {
  // Give the upload a fake head start.
  const percentageUploaded = ref(6);

  const onUploadProgress = async (progressEvent) => {
    percentageUploaded.value = Math.round(
      (100 * progressEvent.loaded) / progressEvent.total
    );
  };

  const uploadFiles = async ({
    file,
    transactionId,
    exactPath,
    initiateProcessing,
  }) => {
    try {
      const userAccessToken = getAccessToken();
      const appCheckToken = await getAppCheckToken();
      const formData = new FormData();
      formData.append("transactionId", transactionId);
      formData.append("exactPath", exactPath);
      formData.append("initiateProcessing", initiateProcessing);
      formData.append("file", file);

      const uploadResponse = await axios.post(
        `${MOVEREADY_FILE_API_URL}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userAccessToken}`,
            "X-Firebase-AppCheck": appCheckToken,
          },
          onUploadProgress,
        }
      );

      return { ...uploadResponse.data, exactPath };
    } catch (ex) {
      if (ex?.response?.status === 413) {
        throw new Error("The file size is too large.");
      }
      throw ex;
    }
  };

  const deleteFile = async (fileIdToDelete) => {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();

    try {
      const deleteResponse = await axios.delete(
        `${MOVEREADY_FILE_API_URL}/files/${fileIdToDelete}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userAccessToken}`,
            "X-Firebase-AppCheck": appCheckToken,
          },
        }
      );

      return deleteResponse;
    } catch (ex) {
      // The file must've been deleted already.
      if (ex.response.status === 404) {
        return ex.response;
      }
      throw new Error(ex);
    }
  };

  const downloadFile = async (fileData) => {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();

    try {
      const downloadResponse = await axios.get(
        `${MOVEREADY_FILE_API_URL}/files/${fileData.fileId}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "X-Firebase-AppCheck": appCheckToken,
          },
        }
      );

      const blob = new Blob([downloadResponse.data], {
        type: fileData.mimetype,
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileData.formattedFileName;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (ex) {
      console.error(ex);
    }
  };

  return {
    deleteFile,
    downloadFile,
    formatTitleFileName,
    formatUploadsAsAttachments,
    getFileIdFromQueryString,
    onUploadProgress,
    percentageUploaded,
    uploadFiles,
  };
}

export default [
  // NTS Sales Part A
  {
    name: "Asking price",
    path: "/propertyPack/priceInformation",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Ownership",
    path: "/propertyPack/ownership",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Energy efficiency",
    path: "/propertyPack/energyEfficiency",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Council Tax",
    path: "/propertyPack/councilTax",
    category: "listing",
    overlay: "nts2023",
  },
  // NTS Sales Part B
  {
    name: "About the building",
    path: "/propertyPack/buildInformation",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Type of construction",
    path: "/propertyPack/typeOfConstruction",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Electricity",
    path: "/propertyPack/electricity",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Water and drainage",
    path: "/propertyPack/waterAndDrainage",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Heating",
    path: "/propertyPack/heating",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Connectivity",
    path: "/propertyPack/connectivity",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Parking",
    path: "/propertyPack/parking",
    category: "listing",
    overlay: "nts2023",
  },
  // NTS Sales Part C
  {
    name: "Listing and conservation",
    path: "/propertyPack/listingAndConservation",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Rights and easements",
    path: "/propertyPack/rightsAndInformalArrangements",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Historical flooding",
    path: "/propertyPack/environmentalIssues/flooding/historicalFlooding",
    category: "listing",
    overlay: "nts2023",
  },
  {
    name: "Planning and development",
    path: "/propertyPack/notices/planningApplication",
    category: "listing",
    overlay: "nts2023",
  },
  // NTS Lettings Part A
  {
    name: "Letting information",
    path: "/propertyPack/lettingInformation",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Energy efficiency",
    path: "/propertyPack/energyEfficiency",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Council Tax",
    path: "/propertyPack/councilTax",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  // NTS Lettings Part B
  {
    name: "About the building",
    path: "/propertyPack/buildInformation",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Type of construction",
    path: "/propertyPack/typeOfConstruction",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Electricity",
    path: "/propertyPack/electricity",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Water and drainage",
    path: "/propertyPack/waterAndDrainage",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Heating",
    path: "/propertyPack/heating",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Connectivity",
    path: "/propertyPack/connectivity",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Parking",
    path: "/propertyPack/parking",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  // NTS Lettings Part C
  {
    name: "Listing and conservation",
    path: "/propertyPack/listingAndConservation",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Rights and easements",
    path: "/propertyPack/rightsAndInformalArrangements",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Historical flooding",
    path: "/propertyPack/environmentalIssues/flooding/historicalFlooding",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  {
    name: "Planning and development",
    path: "/propertyPack/notices/planningApplication",
    category: "lettings-listing",
    overlay: "ntsl2023",
  },
  // BASPI Part A
  {
    name: "Delay factors",
    path: "/propertyPack/delayFactors",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Detailed ownership information",
    path: "/propertyPack/ownership",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Disputes and complaints",
    path: "/propertyPack/disputesAndComplaints",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Alterations and changes",
    path: "/propertyPack/alterationsAndChanges",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Notices",
    path: "/propertyPack/notices",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Specialist issues",
    path: "/propertyPack/specialistIssues",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Fixtures and fittings",
    path: "/propertyPack/fixturesAndFittings",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Water and drainage details",
    path: "/propertyPack/waterAndDrainage",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Connectivity details",
    path: "/propertyPack/connectivity",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Other heating details", // dup with TA6
    path: "/propertyPack/heating",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Insurance",
    path: "/propertyPack/insurance",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Other rights and informal arrangements",
    path: "/propertyPack/rightsAndInformalArrangements",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Other environmental issues",
    path: "/propertyPack/environmentalIssues",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Other issues",
    path: "/propertyPack/otherIssues",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Additional information",
    path: "/propertyPack/additionalInformation",
    category: "material-info",
    overlay: "baspiV4",
  },
  {
    name: "Declaration",
    path: "/propertyPack/consumerProtectionRegulationsDeclaration",
    category: "material-info",
    overlay: "baspiV4",
  },
  // BASPI Part B
  {
    name: "Legal ownership",
    path: "/propertyPack/legalOwners",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Legal boundaries",
    path: "/propertyPack/legalBoundaries",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Services crossing",
    path: "/propertyPack/servicesCrossing",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Electrical works",
    path: "/propertyPack/electricalWorks",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Smart home systems",
    path: "/propertyPack/smartHomeSystems",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Guarantees and warranties",
    path: "/propertyPack/guaranteesWarrantiesAndIndemnityInsurances",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Occupiers of the property", // dupe with 'Occupiers' for TA6
    path: "/propertyPack/occupiers",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Completion and moving",
    path: "/propertyPack/completionAndMoving",
    category: "legal-info",
    overlay: "baspiV4",
  },
  {
    name: "Confirmation of accuracy",
    path: "/propertyPack/confirmationOfAccuracyByOwners",
    category: "legal-info",
    overlay: "baspiV4",
  },
  // TA6
  {
    name: "Property boundaries",
    path: "/propertyPack/legalBoundaries",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Disputes & complaints", // using & to avoid dupe with BASPI
    path: "/propertyPack/disputesAndComplaints",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Notices and proposals",
    path: "/propertyPack/notices",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Alterations, planning and building control",
    path: "/propertyPack/alterationsAndChanges",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Guarantees & warranties", // using & to avoid dupe with BASPI
    path: "/propertyPack/guaranteesWarrantiesAndIndemnityInsurances",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Insurance details", // dupe with "Insurance" for BASPI
    path: "/propertyPack/insurance",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Radon",
    path: "/propertyPack/environmentalIssues/radon",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Japanese knotweed",
    path: "/propertyPack/specialistIssues/japaneseKnotweed",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Rights & informal arrangements",
    path: "/propertyPack/rightsAndInformalArrangements",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Services crossing the property", // dup with BASPI
    path: "/propertyPack/servicesCrossing",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Other charges",
    path: "/propertyPack/additionalInformation/otherCharges",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Occupiers",
    path: "/propertyPack/occupiers",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Electrics",
    path: "/propertyPack/electricalWorks",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Heating details",
    path: "/propertyPack/heating",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Drainage & sewerage details",
    path: "/propertyPack/waterAndDrainage/drainage",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Electricity details",
    path: "/propertyPack/electricity",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Mains water details",
    path: "/propertyPack/waterAndDrainage/water/mainsWater",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Telephone and cable",
    path: "/propertyPack/connectivity",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Transaction information",
    path: "/propertyPack/completionAndMoving",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  {
    name: "Confirmation of accuracy of property information",
    path: "/propertyPack/confirmationOfAccuracyByOwners",
    category: "property-questions",
    overlay: "ta6ed4",
  },
  // TA7
  {
    name: "Ownership and management",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/ownershipAndManagement",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Relevant documents",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/requiredDocuments",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Contact details",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/contactDetails/contacts",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Insurance",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/contactDetails/serviceContactAssignments",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Service charges",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/serviceCharge",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Consents",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/consents",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Complaints",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/disputes",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Alterations",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/alterations",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Enfranchisement",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/enfranchisement",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Building Safety Act",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/buildingSafetyAct",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  {
    name: "Confirmation of accuracy of leasehold information",
    path: "/propertyPack/ownership/ownershipsToBeTransferred/0/leaseholdInformation/confirmationOfAccuracy",
    category: "leasehold-questions",
    overlay: "ta7ed3",
  },
  // TA10
  {
    name: "Basic fittings",
    path: "/propertyPack/fixturesAndFittings/basicFittings",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Kitchen",
    path: "/propertyPack/fixturesAndFittings/kitchen",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Bathroom",
    path: "/propertyPack/fixturesAndFittings/bathroom",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Carpets",
    path: "/propertyPack/fixturesAndFittings/carpets",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Curtains and curtain rails",
    path: "/propertyPack/fixturesAndFittings/curtainsAndCurtainRails",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Light fittings",
    path: "/propertyPack/fixturesAndFittings/lightFittings",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Fitted units",
    path: "/propertyPack/fixturesAndFittings/fittedUnits",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Outdoor area",
    path: "/propertyPack/fixturesAndFittings/outdoorArea",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Television and telephone",
    path: "/propertyPack/fixturesAndFittings/televisionAndTelephone",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Stock of fuel",
    path: "/propertyPack/fixturesAndFittings/stockOfFuel",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Other items",
    path: "/propertyPack/fixturesAndFittings/otherItems",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Confirmation of accuracy of fixtures and fittings",
    path: "/propertyPack/fixturesAndFittings/confirmationOfAccuracy",
    category: "fittings-and-contents",
    overlay: "ta10ed3",
  },
  {
    name: "Sale Ready declaration",
    path: "/propertyPack/saleReadyDeclarations",
    category: "sale-ready",
    overlay: "sr24",
  },
];

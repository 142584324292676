export default Object.freeze({
  // An invoice for the estate agent to pay for the MI pack.
  MATERIAL_INFORMATION_PACK: "materialInformationPack",
  // A purchase order for the vendor or landlord to pay for the MI pack.
  MATERIAL_INFORMATION_PACK_SELLER: "materialInformationPackSeller",
  CONTRACT_READY_PACK: "contractReadyPack",
  CONTRACT_READY_PACK_SELLER: "contractReadyPackSeller",
  SELLER_IDENTITY_CHECK: "identityCheckSeller",
  BUYER_IDENTITY_CHECK: "identityCheckBuyer",
  LANDLORD_IDENTITY_CHECK: "identityCheckLandlord",
  TENANT_IDENTITY_CHECK: "identityCheckTenant",
  CONVEYANCING_QUOTE_SELLER: "conveyancingQuoteSeller",
  CONVEYANCING_QUOTE_BUYER: "conveyancingQuoteBuyer",
  // Future product type?
  MOVE_READY_PACK: "moveReadyPack",
});

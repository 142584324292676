import useUserProfile from "@/composables/userProfile";
import useAuth from "@/composables/auth";

/**
 * No need to block the loading of the paage while the user
 * profile is being fetched.
 */
const loadUserProfile = async (to, from, next) => {
  const { fetchUserProfile, userProfile } = useUserProfile();
  const { isUserAuthenticated } = useAuth();

  if (
    isUserAuthenticated.value &&
    Object.keys(userProfile.value).length === 0
  ) {
    fetchUserProfile();
  }

  next();
};

export default loadUserProfile;

<template>
  <div class="button-group__item">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ButtonGroupItem",
};
</script>

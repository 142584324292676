<script setup>
import { computed, useSlots } from "vue";

defineProps({
  to: {
    type: Object,
    default: () => {
      return {};
    },
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: "",
  },
  rel: {
    type: String,
    default: "",
  },
});

const slots = useSlots();
const hasIconSuffix = computed(() => !!slots["icon-suffix"]);
const hasIconPrefix = computed(() => !!slots["icon-prefix"]);
</script>

<template>
  <component
    :is="isDisabled ? 'a' : 'router-link'"
    :aria-disabled="isDisabled"
    role="link"
    :target="target"
    :rel="rel"
    class="button link-button"
    :class="{
      'button--secondary': isSecondary,
      'button--disabled': isDisabled,
    }"
    :to="to"
  >
    <span class="button__main link-button__main"
      ><span
        class="button__icon-prefix link-button__icon-prefix"
        v-if="hasIconPrefix"
        ><slot name="icon-prefix" /></span
      ><slot /><span
        class="button__icon-suffix link-button__icon-suffix"
        v-if="hasIconSuffix"
        ><slot name="icon-suffix" /></span
    ></span>
  </component>
</template>

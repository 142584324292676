<template>
  <footer class="site-footer" role="contentinfo">
    <div class="site-footer__content">
      <div class="site-footer__topics">
        <div class="site-footer__topic">
          <h4 class="site-footer__topic__heading" id="site-footer-topic-help">
            Moverly for
          </h4>
          <ul class="site-footer__topic__links">
            <li>
              <router-link
                :to="{ name: 'sellers.landing' }"
                aria-labelledby="site-footer-topic-help"
                >Sellers</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'agents.landing' }"
                aria-labelledby="site-footer-topic-help"
                >Agents</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'conveyancers.landing' }"
                aria-labelledby="site-footer-topic-help"
                >Conveyancers</router-link
              >
            </li>
          </ul>
        </div>

        <div class="site-footer__topic">
          <h4 class="site-footer__topic__heading">Legal</h4>
          <ul class="site-footer__topic__links">
            <li>
              <router-link :to="{ name: 'legal.terms' }"
                >Terms and conditions</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'legal.cookies' }"
                >Cookie use</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'legal.privacy' }"
                >Privacy policy</router-link
              >
            </li>
          </ul>
        </div>

        <div class="site-footer__topic site-footer__topic--contact">
          <h4 class="site-footer__topic__heading">Get in touch</h4>
          <p>
            <a href="mailto:hello%moverly.com">hello&commat;moverly.com</a>
          </p>

          <address class="site-address">
            128 City Road <br />London, EC1V 2NX
          </address>
        </div>
      </div>

      <div class="site-footer__footnote">
        <img
          class="site-footer__footnote__logo"
          :src="require('../assets/images/logos/moverly-on-primary.svg')"
          alt="Moverly logo"
        />
        <p class="site-colophon">
          <small>&copy; Moverly 2024 all rights reserved</small>
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
/**
 * 1. The bottom spacing prevent Hubspot from covering any footer text.
 */
.site-footer {
  background: #14424b;
  color: #fff;
  margin: 80px 0 0;
  padding-top: stack(strongest) * 2;
  padding-bottom: stack(strongest) * 2; /* 1 */
  text-align: center;
}

.site-footer__content {
  margin: 0 auto;
  max-width: $layout-max-width;
}

.site-colophon {
  margin: 0;

  small {
    font-size: inherit;
    color: inherit;
  }
}

.site-footer__topics {
  display: flex;
  flex-direction: column;
}

.site-footer__topic {
  margin: 0 0 stack(strongest);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.site-footer__topic__heading {
  display: block;
  font-size: typography-size("body");
  font-weight: 600;
  line-height: typography-line-height("body");
  margin: 0 0 stack();
}

.site-footer__topic__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-footer__footnote {
  display: flex;
  flex-direction: column;
  margin-top: stack("strongest");
}

.site-footer__footnote__logo {
  height: 24px;
  width: auto;
  margin: 0 0 16px;
}

/**
 * 1. Pull out from the site-side gutter.
 */
@include mq("medium-viewport") {
  .site-footer {
    margin-left: negative(gutter(stronger)); /* 1 */
    margin-right: negative(gutter(stronger)); /* 1 */
    padding-left: 40px + gutter(stronger);
    padding-right: 40px + gutter(stronger);
    text-align: initial;
  }

  .site-footer__topics {
    margin-left: -80px;
    flex-direction: row;
    justify-content: space-between;
  }

  .site-footer__topic {
    margin-left: 80px;
  }

  .site-footer__topic--contact {
    margin-left: auto;
  }

  .site-footer__footnote {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .site-footer__footnote__logo {
    margin: 0;
  }
}
</style>

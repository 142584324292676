import useUserProfile from "@/composables/userProfile";
import useOrganisations from "@/composables/organisations";

const requiresUserAndOrganisation = async (to, from, next) => {
  const { fetchUserProfile, userProfile, organisationId } = useUserProfile();
  const { fetchOrganisationUsers, fetchOrganisation } = useOrganisations();

  // I think this is a lazy load check...
  if (Object.keys(userProfile.value).length === 0) {
    await fetchUserProfile();
  }

  await Promise.all([
    fetchOrganisationUsers(organisationId.value),
    fetchOrganisation(organisationId.value),
  ]);
  next();
};

export default requiresUserAndOrganisation;

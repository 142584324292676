import requiresAuth from "../guards/requiresAuth";
import requiresUserProfile from "../guards/requiresUserProfile";

export default [
  {
    path: "/vendors/alto",
    name: "alto",
    beforeEnter: [requiresAuth, requiresUserProfile],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Alto",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "search",
        name: "alto.search",
        component: () =>
          import(
            /* webpackChunkName: "back-office.alto" */ "../../views/back-office/integrations/alto/AltoPropertySearch.vue"
          ),
        meta: {
          title: "Start",
        },
      },
      {
        path: "transaction/add",
        name: "alto.transaction.add",
        component: () =>
          import(
            /* webpackChunkName: "back-office.alto" */ "../../views/back-office/integrations/alto/AltoCreateTransaction.vue"
          ),
        meta: {
          title: "Start",
        },
      },
    ],
  },
];

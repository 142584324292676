import axios from "axios";
import useAuth from "@/composables/auth";
import {
  MOVEREADY_PDTF_API_URL,
  MOVEREADY_ORGANISATION_API_URL,
} from "@/config";
import { getAppCheckToken } from "@/firebase";
import Honeybadger from "@honeybadger-io/js";
import { isPathValid, getSubschemaValidator } from "@pdtf/schemas";
import { ref } from "vue";
import useUpload from "@/composables/file";

const { getAccessToken } = useAuth();
const { formatUploadsAsAttachments } = useUpload();

const verifiedClaimsError = ref(null);
const verifiedClaims = ref([]);
const isLoadingVerifiedClaims = ref(true);

const clearVerifiedClaims = () => {
  verifiedClaims.value = [];
};

/**
 * This is a workaround for the BASPI view, as a user is not logged in
 * when this is generated in the back-end.
 */
const fetchVerifiedClaimsAsClient = async (transactionId) => {
  const userAccessToken = getAccessToken();

  isLoadingVerifiedClaims.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/claims`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
        },
      }
    );
    clearVerifiedClaims();
    updateVerifiedClaims(response.data);
    isLoadingVerifiedClaims.value = false;
  } catch (ex) {
    verifiedClaims.value = [];
    verifiedClaimsError.value = ex;
  } finally {
    isLoadingVerifiedClaims.value = false;
  }
};

const updateVerifiedClaims = (newClaims) => {
  // Cloned to prevent observable side effects.
  // eslint-disable-next-line
  verifiedClaims.value = structuredClone(verifiedClaims.value).concat(
    newClaims
  );
};

let fetchVerifiedClaimsAbortController = null;

const fetchVerifiedClaims = async (
  transactionId,
  abortInFlightRequests = false
) => {
  isLoadingVerifiedClaims.value = true;
  verifiedClaimsError.value = null;

  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    // Not sure if there will be side-effects from this,
    // perhaps a debounce might be better when handling
    // multiple requests from collector updates.
    if (fetchVerifiedClaimsAbortController && abortInFlightRequests) {
      fetchVerifiedClaimsAbortController.abort();
    }

    fetchVerifiedClaimsAbortController = new AbortController();
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/claims`,
      {
        signal: fetchVerifiedClaimsAbortController.signal,
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    clearVerifiedClaims();
    updateVerifiedClaims(response.data);
    isLoadingVerifiedClaims.value = false;
  } catch (ex) {
    // verifiedClaims.value = [];
    verifiedClaimsError.value = ex;
  } finally {
    isLoadingVerifiedClaims.value = false;
  }
};

const addVerifiedClaim = async (transactionId, verifiedClaim) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  const claimsCollection = Array.isArray(verifiedClaim)
    ? verifiedClaim
    : [verifiedClaim];

  try {
    const response = await axios.post(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/claims`,
      claimsCollection,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    updateVerifiedClaims(response.data);
    verifiedClaimsError.value = null;
  } catch (ex) {
    verifiedClaimsError.value = ex;
    Honeybadger.notify(ex, {
      message: "Cannot save verified claim",
      name: "verifiedClaims.js",
      params: {
        transactionId,
        verifiedClaim,
      },
    });
    throw ex;
  }
};

const createVerifiedClaim = ({
  voucherName,
  path,
  data,
  documents = [],
  ignoreSchemaValidation = false,
}) => {
  const dataClone = structuredClone(data);
  const claims = validClaimLineItems(path, dataClone, ignoreSchemaValidation);
  return createVerifiedClaimFromClaims({
    voucherName,
    claims,
    documents,
  });
};

const createVerifiedClaimFromClaims = ({
  voucherName,
  claims,
  documents = [],
}) => {
  const newVerifiedClaim = {
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence: [],
    },
  };
  const newVouch = {
    type: "vouch",
    verification_method: { type: "auth" },
    attestation: {
      type: "digital_attestation",
      voucher: {
        name: voucherName,
      },
    },
  };

  if (documents && documents.length > 0) {
    const attachments = documents.map((documentToAttach) =>
      createAttachment(documentToAttach)
    );
    newVouch.attachments = attachments;
  }

  newVerifiedClaim.verification.evidence = [newVouch];
  newVerifiedClaim.claims = claims;
  return newVerifiedClaim;
};

/**
 * Claim line items must individually be valid (per the core schema, without overlays)
 * If not, we decompose the object into line items containing valid sub-objects
 */
const validClaimLineItems = (path, data, ignoreSchemaValidation) => {
  if (ignoreSchemaValidation) {
    return {
      [path]: data,
    };
  }

  if (!isPathValid(path)) return undefined;
  const validator = getSubschemaValidator(path, undefined, null);
  const isValid = validator(data);
  // ignore a partially complete date field which is passed as null
  if (data === null) return undefined;
  if (isValid) {
    return {
      [path]: data,
    };
  }
  if (Array.isArray(data)) {
    const lineItems = data.map((item, index) => {
      return validClaimLineItems(`${path}/${index}`, item);
    });
    const returnObj = {};
    lineItems.forEach((item) => Object.assign(returnObj, item));
    return returnObj;
  }

  if (typeof data === "object") {
    const lineItems = Object.entries(data).map(([property, value]) =>
      validClaimLineItems(`${path}/${property}`, value)
    );
    const lineItemPairs = lineItems
      .map((item) => {
        // ignore partially complete date fields
        if (item === undefined) return [];
        return Object.entries(item);
      })
      .flat();
    return Object.fromEntries(lineItemPairs);
  }
  // invalid primitive type, discard
  return undefined;
};

const generateClaimsFromDiff = (obj1, obj2, prefixPath = "") => {
  function buildPath(prefix, key) {
    return prefix ? `${prefix}/${key}` : `/${key}`;
  }

  function compareObjects(o1, o2, path = "") {
    const claims = {};

    if (Array.isArray(o1) && Array.isArray(o2)) {
      let requiresFullArrayClaim = false;
      const arrayClaims = {};

      const maxLength = Math.max(o1.length, o2.length);
      let diffCount = 0;

      for (let i = 0; i < maxLength; i++) {
        if (i >= o1.length) {
          // New element in o2
          diffCount++;
          if (diffCount > 1) {
            requiresFullArrayClaim = true;
            break;
          }
          arrayClaims[`${path}/-`] = o2[i];
        } else if (i >= o2.length) {
          // Element removed from o2
          requiresFullArrayClaim = true;
          break;
        } else {
          const currentPath = buildPath(path, i);
          const elementClaims = compareObjects(o1[i], o2[i], currentPath);
          if (Object.keys(elementClaims).length > 0) {
            Object.assign(arrayClaims, elementClaims);
          }
        }
      }

      if (requiresFullArrayClaim) {
        claims[path] = o2;
      } else {
        Object.assign(claims, arrayClaims);
      }
    } else {
      // Check keys in the first object
      for (const key in o1) {
        if (Object.hasOwnProperty.call(o1, key)) {
          const currentPath = buildPath(path, key);
          if (!Object.hasOwnProperty.call(o2, key)) {
            claims[currentPath] = null; // This indicates deletion, if needed
          } else if (
            typeof o1[key] === "object" &&
            typeof o2[key] === "object" &&
            o1[key] !== null &&
            o2[key] !== null
          ) {
            Object.assign(
              claims,
              compareObjects(o1[key], o2[key], currentPath)
            );
          } else if (o1[key] !== o2[key]) {
            claims[currentPath] = o2[key];
          }
        }
      }

      // Check keys in the second object
      for (const key in o2) {
        if (
          Object.hasOwnProperty.call(o2, key) &&
          !Object.hasOwnProperty.call(o1, key)
        ) {
          const currentPath = buildPath(path, key);
          claims[currentPath] = o2[key];
        }
      }
    }

    return claims;
  }

  // apply prefix path to each key
  const claims = compareObjects(obj1, obj2);
  const claimsWithPrefix = Object.fromEntries(
    Object.entries(claims).map(([key, value]) => [`${prefixPath}${key}`, value])
  );
  return claimsWithPrefix;
};

const createAttachment = ({
  desc,
  url,
  hash = "?",
  algorithm = "?",
  pdtfSchemaPath,
}) => {
  return {
    desc,
    digest: {
      alg: algorithm,
      value: hash,
    },
    url,
    pdtfSchemaPath,
  };
};

const fetchVerifiedClaimsAsOrganisation = async (
  transactionId,
  organisationId,
  abortInFlightRequests = false
) => {
  isLoadingVerifiedClaims.value = true;
  verifiedClaimsError.value = null;
  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    // Not sure if there will be side-effects from this,
    // perhaps a debounce might be better when handling
    // multiple requests from collector updates.
    if (fetchVerifiedClaimsAbortController && abortInFlightRequests) {
      fetchVerifiedClaimsAbortController.abort();
    }

    fetchVerifiedClaimsAbortController = new AbortController();
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/transactions/${transactionId}/claims`,
      {
        signal: fetchVerifiedClaimsAbortController.signal,
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    clearVerifiedClaims();
    updateVerifiedClaims(response.data);
    isLoadingVerifiedClaims.value = false;
  } catch (ex) {
    verifiedClaims.value = [];
    verifiedClaimsError.value = ex;
  } finally {
    isLoadingVerifiedClaims.value = false;
  }
};

const mapUploadedFileToClaimData = (uploadedFile) => {
  const { displayName, formattedFileName, mimeType, fileId } = uploadedFile;

  return {
    displayName,
    fileName: formattedFileName,
    mimeType,
    externalIds: {
      Moverly: fileId,
    },
  };
};

const generateClaimsFromUploadedFiles = (
  uploadedFiles,
  { voucherName, path = "/propertyPack/documents/-" }
) => {
  const fileClaims = uploadedFiles.map((file) => {
    const data = mapUploadedFileToClaimData(file);
    const documents = formatUploadsAsAttachments([file]);
    const fileClaim = createVerifiedClaim({
      path,
      voucherName,
      data,
      documents,
    });

    return fileClaim;
  });

  return fileClaims;
};

export default function useVerifiedClaims() {
  return {
    addVerifiedClaim,
    clearVerifiedClaims,
    createAttachment,
    createVerifiedClaim,
    createVerifiedClaimFromClaims,
    fetchVerifiedClaims,
    fetchVerifiedClaimsAsClient,
    fetchVerifiedClaimsAsOrganisation,
    generateClaimsFromUploadedFiles,
    generateClaimsFromDiff,
    isLoadingVerifiedClaims,
    validClaimLineItems,
    verifiedClaims,
    verifiedClaimsError,
  };
}

import requiresAuth from "../guards/requiresAuth";
import impersonateUser from "../guards/impersonateUser";
import requiresUserAndOrganisation from "../guards/requiresUserAndOrganisation";

export default [
  {
    path: "/properties",
    name: "back-office.properties",
    beforeEnter: [requiresAuth, requiresUserAndOrganisation],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Properties",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "add",
        name: "agent.transaction.create",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/FindAddress.vue"
          ),
        meta: {
          title: "Add property",
        },
      },
      {
        path: "/agent/properties",
        name: "back-office.dashboard",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/PropertiesDashboard.vue"
          ),
        meta: {
          title: "View properties",
        },
      },
      {
        path: ":transactionId",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/BackOfficeTransactionRouter.vue"
          ),
        children: [
          /**
           * This could be a local emulators issue, but this route is here
           * as the page loads with empty data; Firebase hasn't updated yet.
           * This has started since the v. claims are fetched with an endpoint
           * not a snapshot listener.
           */
          {
            path: "view",
            name: "agent.transaction.view",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/ViewTransaction.vue"
              ),
            meta: {
              title: "View property",
            },
          },
          {
            path: "view/upgrade/payment",
            name: "back-office.upgrade.billing",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/UpgradeBilling.vue"
              ),
            meta: {
              title: "Payment",
            },
          },
          {
            path: "view/upgrade/options",
            name: "agent.transaction.upgrade.options",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/UpgradeOptions.vue"
              ),
            meta: {
              title: "Service options",
            },
          },
          {
            path: "view/upgrade/titles",
            name: "agent.transaction.upgrade.titles",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/VerifyTitles.vue"
              ),
            meta: {
              title: "Add property",
            },
          },
          {
            path: "view/upgrade/invites",
            name: "agent.transaction.upgrade.invites",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/InviteParticipants.vue"
              ),
            meta: {
              title: "Add vendors",
            },
          },
          {
            path: "document/:fileId/compare",
            name: "back-office.documents.compare",
            props: (route) => {
              const { fileId } = route.params;

              return { fileId };
            },
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/FileProcessing.vue"
              ),
            meta: {
              title: "View properties",
            },
          },
        ],
      },
    ],
  },
  /**
   * The certificate route is a special case - it expects the generatePdfDownload
   * method to log in in the back-end to generate a PDF of the certificate page.
   */
  {
    path: "/properties/:transactionId",
    beforeEnter: [impersonateUser, requiresUserAndOrganisation],
    component: () =>
      import(
        /* webpackChunkName: "back-office" */ "../../views/back-office/BackOfficeTransactionRouter.vue"
      ),
    children: [
      {
        path: "certificate",
        name: "agent.transaction.certificate",
        component: () =>
          import("../../views/back-office/transactions/ViewCertificate.vue"),
        meta: {
          title: "Material Information Certificate",
          layout: "PrintLayout",
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "back-office.admin",
    beforeEnter: [requiresAuth, requiresUserAndOrganisation],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Admin",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "transactions",
        name: "back-office.admin.transactions.list",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/ListAdminTransactions.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "organisations",
        name: "back-office.admin.organisation.list",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/organisation/ListOrganisations.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "emails",
        name: "back-office.admin.emails",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/AdminEmails.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "webhooks",
        name: "back-office.admin.webhooks",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Webhooks.vue"
          ),
        meta: {
          title: "Webhooks",
        },
      },

      {
        path: "users",
        name: "back-office.admin.users",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Users.vue"
          ),
        meta: {
          title: "Users",
        },
      },
      {
        path: "tools",
        name: "back-office.admin.tools",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Tools.vue"
          ),
        meta: {
          title: "Tools",
        },
      },
      {
        path: "organisations/:organisationId",
        name: "back-office.admin.organisation.edit",
        props: (route) => {
          const { organisationId } = route.params;

          return { organisationId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/organisation/ManageOrganisation.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
      {
        path: "transactions/:transactionId",
        name: "back-office.admin.transaction.view",
        props: (route) => {
          const { transactionId } = route.params;

          return { transactionId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/OrganisationTransaction.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
      {
        path: "organisations/:organisationId/transactions/:transactionId",
        name: "back-office.admin.organisation.transaction.view",
        props: (route) => {
          const { organisationId, transactionId } = route.params;

          return { organisationId, transactionId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/OrganisationTransaction.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
    ],
  },
  {
    path: "/organisation",
    name: "back-office.organisation",
    beforeEnter: [requiresAuth, requiresUserAndOrganisation],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Organisation",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "settings",
        name: "back-office.organisation.details",
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/ViewOrganisation.vue"
          ),
        meta: {
          title: "Settings",
        },
      },
    ],
  },
  {
    path: "/organisation/user/account",
    name: "back-office.user.account",
    beforeEnter: [requiresAuth, requiresUserAndOrganisation],
    component: () =>
      import("../../views/back-office/organisation/ViewAccount.vue"),

    meta: {
      title: "My account",
      layout: "BackOfficeLayout",
    },
  },
  {
    path: "/payment/3dsecure",
    name: "back-office.stripe.3dsecure",
    component: () =>
      import(
        /* webpackChunkName: "upfront-searches" */ "../../views/back-office/3DSecure.vue"
      ),
    meta: {
      title: "Loading",
      layout: "EmptyLayout",
    },
  },
];
